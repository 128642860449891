import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'

import RmDatePicker from './RmDatePicker.jsx'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'
import HepPopup from './HelpPopup.jsx'

const ArsredovisningDatumEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  return (
    <React.Fragment>
      <div>
        Datering av årsredovisning{' '}
        <HepPopup
          text={
            'Årsredovisningar för räkenskapsår som startar 2024-07-01 eller senare skall inkludera ett datum för avgivande av årsredovisning (datum när årsredovisningen skapades). Bolagsverket kontrollerar att samtliga företrädare har signerat på detta datum eller senare.'
          }
        />
      </div>
      <Box mt={1} />
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '50px' }}>
        <div>
          <RmDatePicker
            className={`automation-arsredovisning-datum`}
            defaultValue={annualReport.arsredovisningDatum || null}
            onChange={async (newDate) => {
              API.saveAnnualReportValue(
                annualReport.reportId,
                'arsredovisningDatum',
                newDate || '',
                abortSignal,
                onChange,
                {
                  immediate: true,
                }
              )
            }}
            disabled={disabled}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

ArsredovisningDatumEditor.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ArsredovisningDatumEditor
