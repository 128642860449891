const HttpErrorCodes = {
  INVALID_ROUTE: 'invalid_route',
  MISSING_REQUIRED_PARAMETER: 'missing_required_parameter',
  INVALID_PARAMETER: 'invalid_parameter',
  FEATURE_DISABLED: 'feature_disabled',
  ACCESS_DENIED: 'access_denied',
  BAD_REQUEST: 'bad_request',

  /* If client sees this errorCode, it should show the corresponding attached
     "errorMessage" directly in the UI. As long as the client side does this,
     the rest of the error handling can be implemented "server-side only" which
     is more convenient than returning and error code from the server and then
     translating the error code into a human readable error on the client side
     (keeping client/server sides in sync in this later case is a bit tedious).
     For more advanced errors, one can still resort to returning a unique
     errorCode with custom handling on the client side.
   */
  UI_ERROR_MESSAGE: 'ui_error_message',

  APP_SUBSCRIPTION_EXPIRED: 'app_subscription_expired',
  PASSWORD_TOKEN_INVALID: 'password_token_invalid',
  PASSWORD_TOKEN_REVOKED: 'password_token_revoked',
  PASSWORD_TOKEN_EXPIRED: 'password_token_expired',
  EMAIL_CONFIRMATION_TOKEN_EXPIRED: 'email_confirmation_token_expired',
  WEAK_PASSWORD: 'weak_password',

  SKV_READABLE_ERROR_MESSAGE: 'skv_readable_error_message',

  STRIPE_CARD_ERROR: 'stripe_card_error',

  USES_OLDID: 'uses_oldid',
}

export default HttpErrorCodes
