import React from 'react'
import PropTypes from 'prop-types'
import Popper from '@mui/material/Popper'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'

const HelpPopup = ({ text }) => {
  const [anchorEl, setAnchorEl] = React.useState()

  return (
    <React.Fragment>
      <IconButton size="small" variant={'outlined'} onClick={(ev) => setAnchorEl(anchorEl ? null : ev.currentTarget)}>
        <HelpOutlineIcon fontSize="small" />
      </IconButton>
      <Popper style={{ width: '35%' }} open={Boolean(anchorEl)} placement={'top-end'} anchorEl={anchorEl}>
        <Paper style={{ padding: '15px' }}>{text}</Paper>
      </Popper>
    </React.Fragment>
  )
}

HelpPopup.propTypes = {
  text: PropTypes.string.isRequired,
}

export default HelpPopup
