import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress/index.js'
import Button from '@mui/material/Button/index.js'
import { withRouter } from 'react-router'
import dayjs from 'dayjs'
import LoadingButton from '@mui/lab/LoadingButton/index.js'
import SendIcon from '@mui/icons-material/Send.js'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box/index.js'

import RmClickToSelect from './RmClickToSelect.jsx'
import { useAppFetch, useAbortSignal, useWaningPoller } from '../../AppFetch.js'
import API from '../../API.js'
import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'
import { getIsApproved, getAnnualReportIssuer } from '../../../shared-universal/AnnualReportUtils.js'
import SubmitButton from './SubmitButton.jsx'
import RmTooltip from './RmTooltip.jsx'
import FlashMessage from '../FlashMessage.jsx'
import HttpErrorCodes from '../../../shared-universal/HttpErrorCodes.js'
import RemakerBuyingCard from './RemakerBuyingCard.jsx'
import RemakerReceipt from './RemakerReceipt.jsx'
import { getUrlParam } from '../../UrlUtils.js'
import { stringToBoolean } from '../../../shared-universal/Utils.js'
import BolagsverketActionMenu from './BolagsverketActionMenu.jsx'

const formatNumberExact = (number) =>
  number.toLocaleString('en-US', { minimumFractionDigits: 2 })?.replace(/,/g, ' ').replace(/\./g, ',')

const Dokument = ({ history, annualReport, onChange, userConfig }) => {
  const abortSignal = useAbortSignal()
  const amountTdStyle = {
    paddingLeft: '30px',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  }
  const {
    loading: inlamningsstatusLoading,
    responseJson: inlamningsstatusResponse,
    refetch,
  } = useAppFetch(`/api/up/inlamningsstatus/${annualReport.reportId}`)
  const [submittingToBolagsverket, setSubmittingToBolagsverket] = React.useState(false)
  const [submittingToSkatteverket, setSubmittingToSkatteverket] = React.useState(false)
  const [ink2Error, setInk2Error] = React.useState('')

  const inlamningstatusPollerActive =
    !inlamningsstatusResponse ||
    (inlamningsstatusResponse.locked &&
      getIsApproved(annualReport, 'fardigstall') &&
      !inlamningsstatusResponse.submitBlockBolagsverket)
  useWaningPoller(inlamningstatusPollerActive, refetch)

  const ink2apiEnabled = userConfig.getConfigKey(UserConfigKeys.INK2_API_ENABLED)

  if (!annualReport.goodToGo) {
    return (
      <div>
        <p>
          Efter betalningen är godkänd kommer du från denna sidan kunna skicka in årsredovisning/deklaration digitalt
          till Bolagsverket/Skatteverket.
        </p>
        <RemakerBuyingCard annualReport={annualReport} onChange={onChange} />
      </div>
    )
  }

  const atleastOneUploadedToEgetUtrymme =
    inlamningsstatusResponse && inlamningsstatusResponse.bolagsverketInlamningsstatus.length > 0

  const atleastOneInlIsSigned =
    inlamningsstatusResponse &&
    inlamningsstatusResponse.bolagsverketInlamningsstatus.some((inlStat) =>
      inlStat.handelser.some((handelse) => handelse.status === 'arsred_inkommen')
    )

  const isAnnualMeetingDateIsInTheFuture = dayjs().isBefore(dayjs(annualReport.annualMeetingDate))
  const showIxbrlUrlParam = stringToBoolean(getUrlParam('ixbrl'))
  return (
    <React.Fragment>
      {annualReport.locked && (
        <Button
          variant="outlined"
          onClick={async () => {
            await API.unlockReport(annualReport.reportId, abortSignal)
            await onChange()
            refetch()
          }}
        >
          Lås upp för editering
        </Button>
      )}
      <RemakerReceipt annualReport={annualReport} />
      <br />
      <React.Fragment>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <div>
            <RmTooltip
              visible={!getIsApproved(annualReport, 'fardigstall')}
              title="Du måste godkänna sidan 'Färdigställ' innan du kan skicka in årsredovisningen."
            >
              <RmTooltip
                visible={isAnnualMeetingDateIsInTheFuture}
                title={`Du kan skicka in årsredovisningen först ${annualReport.annualMeetingDate} när årsstämman är genomförd.`}
              >
                <RmTooltip
                  title="En årsredovisning är redan registrerad."
                  visible={inlamningsstatusResponse?.submitBlockBolagsverket}
                >
                  <SubmitButton
                    annualReport={annualReport}
                    onChange={async () => {
                      await onChange()
                      refetch()
                    }}
                    atleastOneUploadedToEgetUtrymme={atleastOneUploadedToEgetUtrymme}
                    onSubmitStart={() => setSubmittingToBolagsverket(true)}
                    onSubmitStop={() => setSubmittingToBolagsverket(false)}
                    submitting={submittingToBolagsverket}
                    disabled={
                      submittingToBolagsverket ||
                      inlamningsstatusLoading ||
                      inlamningsstatusResponse?.submitBlockBolagsverket ||
                      isAnnualMeetingDateIsInTheFuture ||
                      !getIsApproved(annualReport, 'fardigstall')
                    }
                  />
                </RmTooltip>
              </RmTooltip>
            </RmTooltip>
          </div>
          {submittingToBolagsverket && <div>Uppladdning till Bolagsverket kan ta 20 till 30 sekunder.</div>}
        </div>
        <br />
        <br />
        {inlamningsstatusLoading && <CircularProgress />}
        {!inlamningsstatusLoading && <div className="automation-inlamningsstatus-loaded"></div>}
        {!inlamningsstatusLoading &&
          !submittingToBolagsverket &&
          inlamningsstatusResponse &&
          inlamningsstatusResponse.bolagsverketInlamningsstatus.length === 0 && (
            <div>
              Status hos Bolagsverket: <b>Ej uppladdad</b>
            </div>
          )}
        <br />
        <br />
        {!inlamningsstatusLoading &&
          inlamningsstatusResponse &&
          inlamningsstatusResponse.bolagsverketInlamningsstatus.map((inlStat) => {
            // Any "handelse" från BV (other than "test") indicates that the user has signed the document in "eget utrymme".
            // Note that this does not mean that the annual report has been registered, it could have been signed and then
            // rejected (for example when status "arsred_forelaggande_skickat" is seen, but also when "arsred_komplettering_inkommen"
            // is set as the status).
            const isSigned = inlStat.handelser.some((handelse) => handelse.status !== 'test')
            return (
              <React.Fragment key={inlStat.inlamningsId}>
                <div>
                  Årsredovisning skapad {inlStat.created}
                  {!isSigned ? ' (uppladdad till eget utrymme, ej signerad)' : ''}&nbsp;&nbsp;&nbsp;
                  {!isSigned && !inlamningsstatusResponse?.submitBlockBolagsverket && (
                    <div style={{ display: 'inline-flex', gap: '4px' }}>
                      <a href={inlStat.url} target="_blank" rel="noopener noreferrer">
                        <Button variant={atleastOneInlIsSigned ? 'outlined' : 'contained'}>Signera</Button>
                      </a>
                      <BolagsverketActionMenu bolagsverketSignLink={inlStat.url} />
                    </div>
                  )}
                </div>
                <ul>
                  {inlStat.handelser.map((handelse) => (
                    <li key={handelse.handelseId}>
                      {handelse.tid} {handelse.statusText}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            )
          })}
      </React.Fragment>
      <br />
      <br />
      Ladda hem din årsredovisning som{' '}
      <a
        href={
          annualReport.signMethod === 'bankid' && annualReport.hasOriginalSigned
            ? `/api/up/download-signed-pdf?reportId=${annualReport.reportId}`
            : `/api/up/render-report-pdf?reportId=${annualReport.reportId}`
        }
      >
        PDF
      </a>
      <br />
      <br />
      {!ink2apiEnabled && (
        <React.Fragment>
          Ladda hem <a href={`/api/up/render-sru?reportId=${annualReport.reportId}&type=info`}>info.sru</a> och{' '}
          <a href={`/api/up/render-sru?reportId=${annualReport.reportId}&type=blanketter`}>blanketter.sru</a> (för att
          lämna in din INK2 deklaration behöver du ladda upp SRU filerna till Skatteverkets Filöverföringstjänst och
          sedan signera med bank-id)
          <br />
        </React.Fragment>
      )}
      {ink2apiEnabled && (
        <React.Fragment>
          <LoadingButton
            variant="contained"
            loading={submittingToSkatteverket}
            loadingPosition="end"
            endIcon={<SendIcon />}
            onClick={async () => {
              setSubmittingToSkatteverket(true)
              const response = await API.lamnaInInk2(annualReport.reportId, abortSignal)
              setSubmittingToSkatteverket(false)
              if (response.errorCode === HttpErrorCodes.SKV_READABLE_ERROR_MESSAGE) {
                setInk2Error(`Felmeddelande från Skatteverket: ${response.errorMessage}`)
              } else if (response.status !== 'ok') {
                setInk2Error('Ett fel uppstod när deklarationen skulle skickas in')
              }
              refetch()
              await onChange()
            }}
            disabled={
              inlamningsstatusLoading ||
              inlamningsstatusResponse?.submitBlockSkatteverket ||
              submittingToSkatteverket ||
              !getIsApproved(annualReport, 'fardigstall')
            }
            className="automation-submit-to-skatteverket"
          >
            Ladda upp deklaration till eget utrymme på Skatteverket
          </LoadingButton>
          <FlashMessage message={ink2Error} />
          <React.Fragment>
            {inlamningsstatusLoading && <CircularProgress />}
            <br />
            <br />
            {!inlamningsstatusLoading &&
              inlamningsstatusResponse &&
              inlamningsstatusResponse.skatteverketInlamningsstatus.map((inlStat) => {
                return (
                  <React.Fragment key={inlStat.inlamningsId}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginBottom: '16px', lineHeight: '40px' }}>
                        Deklaration skapad {inlStat.created}
                        {!inlStat.markedAsSigned && (
                          <React.Fragment>
                            <Button
                              variant={inlStat.signButtonClicked ? 'outlined' : 'contained'}
                              onClick={async () => {
                                window.open(inlStat.responseUrl, '_blank')
                                await API.setInk2SignButtonClicked(inlStat.inlamningsId, abortSignal)
                                refetch()
                              }}
                              style={{ marginLeft: '16px' }}
                            >
                              Signera
                            </Button>
                            <Button
                              variant={inlStat.signButtonClicked ? 'contained' : 'outlined'}
                              onClick={async () => {
                                await API.setMarkedAsSigned(inlStat.inlamningsId, true, abortSignal)
                                refetch()
                                onChange()
                              }}
                              style={{ marginLeft: '12px' }}
                            >
                              Markera som signerad
                            </Button>
                          </React.Fragment>
                        )}
                        {inlStat.markedAsSigned && (
                          <React.Fragment>
                            {', '}
                            markerad som signerad {inlStat.markedAsSigned}
                            <IconButton
                              size="small"
                              variant={'outlined'}
                              onClick={async () => {
                                await API.setMarkedAsSigned(inlStat.inlamningsId, false, abortSignal)
                                refetch()
                                onChange()
                              }}
                              style={{ marginLeft: '12px' }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
          </React.Fragment>
        </React.Fragment>
      )}
      <br />
      {(userConfig.getConfigKey(UserConfigKeys.SHOW_IBXRL_DOWNLOAD_LINK) || showIxbrlUrlParam) && (
        <React.Fragment>
          Ladda hem <a href={`/api/up/render-ixbrl?reportId=${annualReport.reportId}`}>ixbrl</a>
          <br />
          <br />
        </React.Fragment>
      )}
      {getAnnualReportIssuer(annualReport) !== 'Likvidatorn' && (
        <React.Fragment>
          {annualReport.vouchers.length === 0 && (
            <div>Inga verifikat har genererats och din bokföring är redan komplett.</div>
          )}
          {annualReport.vouchers.length > 0 && (
            <>
              <p>Nedanstående verifikat har genererats och behöver läggas till i ditt bokföringsprogram.</p>
              {!annualReport.financialYears[0].filename && annualReport.vouchers.length !== 0 && (
                <p>
                  Eftersom denna årsredovisning inte är skapad från en bokföringsexport (SIE-fil) så är verifikaten
                  nedan baserade på manuellt inmatade siffror och inkluderar inte ören, du kan därför behöva justera
                  beloppet för årets resultat så att det matchar din bokföring.
                </p>
              )}
              <div style={{ border: '1px solid #AAA', padding: '1em' }}>
                <table>
                  <tbody>
                    {annualReport.vouchers
                      .map((voucher) => {
                        return (
                          <React.Fragment key={voucher.description}>
                            <tr>
                              <td colSpan={3}>
                                <span style={{ fontWeight: 'bold' }}>{voucher.date}: </span>
                                {voucher.description}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ fontWeight: 'bold' }}>Konto</td>
                              <td style={{ ...amountTdStyle, fontWeight: 'bold' }}>Debet</td>
                              <td style={{ ...amountTdStyle, fontWeight: 'bold' }}>Kredit</td>
                            </tr>

                            {voucher.trans.map(({ account, accountName, amount }) => (
                              <tr key={account}>
                                <td style={{ width: '100%' }}>{account + ': ' + accountName}</td>
                                <td style={amountTdStyle}>
                                  <RmClickToSelect text={amount < 0 ? '' : formatNumberExact(amount)} />
                                </td>
                                <td style={amountTdStyle}>
                                  <RmClickToSelect text={amount < 0 ? formatNumberExact(-1 * amount) : ''} />
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        )
                      })
                      .reduce((acc, val) => (
                        <React.Fragment>
                          {acc}
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                          {val}
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </React.Fragment>
      )}
      <Box mt={2} />
      <div>
        Information om hur kostnaden för Min Årsredovisning bokförs finns{' '}
        <a href="https://www.minarsredovisning.se/artiklar/bokfor-kostnad-min-arsredovisning/">här</a>.
      </div>
    </React.Fragment>
  )
}

Dokument.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  history: PropTypes.object.isRequired,
  userConfig: PropTypes.object.isRequired,
}

export default withRouter(withUserConfig(Dokument))
