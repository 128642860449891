import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'
import Button from '@mui/material/Button/index.js'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CircularProgress from '@mui/material/CircularProgress'
import SendIcon from '@mui/icons-material/Send'

import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'
import { useAppFetch, useAbortSignal, useWaningPoller } from '../../AppFetch.js'
import API from '../../API.js'
import CoaSignerEditor from './CoaSignerEditor.jsx'
import SignatureDatesEditor from './SignatureDatesEditor.jsx'
import ArsstammaDatumEditor from './ArsstammaDatumEditor.jsx'
import AuditDateEditor from './AuditDateEditor.jsx'
import EditablePersonnummer from './EditablePersonnummer.jsx'
import RmTextField from './RmTextField.jsx'
import EditableXbrlValue from './EditableXbrlValue.jsx'
import { renderEditableReadonlyRow } from './EditableTableUtils.jsx'
import RemakerBuyingCard from './RemakerBuyingCard.jsx'
import RemakerReceipt from './RemakerReceipt.jsx'
import RmRadioButtons from './RmRadioButtons.jsx'
import RmCheckbox from './RmCheckbox.jsx'
import RmTooltip from './RmTooltip.jsx'
import { isValidPersonnummer, isValidEmail } from '../../../shared-universal/Utils.js'
import { getIsApproved, shouldIncludeArsredovisningDatum } from '../../../shared-universal/AnnualReportUtils.js'
import ModifieradRevisionsberattelseEditor from './ModifieradRevisionsberattelseEditor.jsx'
import ArsredovisningDatumEditor from './ArsredovisningDatumEditor.jsx'

const FardigstallEditor = ({ userConfig, annualReport, onChange, isApproved }) => {
  const FIRST_CELL_WIDTH = '240px'
  const abortSignal = useAbortSignal()
  const [coaSignerEmailReactKey, setCoaSignerEmailReactKey] = useState(0)
  const [isPreparingSigning, setIsPreparingSigning] = useState(false)

  const { responseJson: signingStatusResponse, refetch: signingStatusRefetch } = useAppFetch(
    `/api/up/signing-status/${annualReport.reportId}`
  )
  const signingStatusPollerActive =
    annualReport.signMethod === 'bankid' && annualReport.hasOriginal && !annualReport.hasOriginalSigned
  useWaningPoller(signingStatusPollerActive, signingStatusRefetch)
  const clientSideSignatureCount =
    annualReport.signatures.filter((sig) => Boolean(sig.signedAt)).length + (annualReport.auditorSignedAtSv ? 1 : 0)
  const serverSideSignatureCount = signingStatusResponse?.signatureCount || 0
  useEffect(() => {
    if (clientSideSignatureCount !== serverSideSignatureCount) {
      onChange()
    }
  }, [clientSideSignatureCount, serverSideSignatureCount, onChange])

  const coaSigner = annualReport.coaSigner
    ? annualReport.signatures.find((sig) => sig.foretradareId === annualReport.coaSigner)
    : undefined
  const arsstammaJusteradDispositionEnabled = userConfig.getConfigKey(
    UserConfigKeys.ARSSTAMMA_JUSTERAD_DISPOSITION_ENABLED
  )
  const revberModifiedEnabled = userConfig.getConfigKey(UserConfigKeys.REVBER_MODIFIED_ENABLED)

  const hasReturnedFromCheckout = new URLSearchParams(location.search).get('monitor')
  useEffect(() => {
    let intervalId
    if (!annualReport.goodToGo && hasReturnedFromCheckout) {
      intervalId = setInterval(onChange, 2000)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [hasReturnedFromCheckout, annualReport.goodToGo, onChange])

  if (!annualReport.goodToGo) {
    if (hasReturnedFromCheckout) {
      return <div>Väntar på betalning</div>
    }
    return (
      <div>
        <p>Efter betalning är godkänd kan du färdigställa och lämna in din årsredovisning.</p>
        <RemakerBuyingCard annualReport={annualReport} onChange={onChange} />
      </div>
    )
  }

  const uniquePersonNr = [...new Set(annualReport.signatures.map((sig) => sig.personNr))].length
  const signingPrepareIsBlocked =
    annualReport.locked ||
    !getIsApproved(annualReport, 'noter') ||
    annualReport.signatures.some((sig) => !isValidPersonnummer(sig.personNr)) ||
    uniquePersonNr !== annualReport.signatures.length ||
    annualReport.signatures.some((sig) => !isValidEmail(sig.email)) ||
    (annualReport.revisionsberattelseKrav && !isValidPersonnummer(annualReport.auditorSsn)) ||
    (annualReport.revisionsberattelseKrav && !isValidEmail(annualReport.auditorEmail))
  const signingUrl = annualReport.signatures[0].signingUrl

  return (
    <React.Fragment>
      <RemakerReceipt annualReport={annualReport} />
      {annualReport.signMethod === 'bankid' && annualReport.locked && (
        <Button
          variant={'outlined'}
          onClick={async () => {
            await API.unlockReport(annualReport.reportId, abortSignal)
            onChange()
          }}
        >
          Radera original och lås upp årsredovisning
        </Button>
      )}

      <h3>Hur vill du signera originalet av årsredovisningen?</h3>
      <RmRadioButtons
        value={annualReport.signMethod}
        disabled={isApproved || annualReport.locked}
        items={[
          {
            value: 'paper',
            label: 'Skriv ut PDF och signera på papper',
            helperText:
              'Även om du signerar/arkiverar orignalet på papper så kan du göra en digital inlämning till Bolagsverket (där fastställelseintyget signeras med BankID)',
            className: 'automation-signmethod-paper',
          },
          ...(annualReport.signMethod === 'bankid' || userConfig.getConfigKey(UserConfigKeys.ESIGN_VENDOR) !== 'none'
            ? [
                {
                  value: 'bankid',
                  label: 'Signera digitalt med BankID och arkivera själv',
                  helperText: 'Signeras genom tredjepartstjänst (ingen extra kostnad)',
                  className: 'automation-signmethod-bankid',
                },
              ]
            : []),
          {
            value: 'external',
            label: 'Ladda hem PDF för e-signering i annat verktyg',
            helperText:
              'För dig som redan har konto på en annan e-signeringstjänst (Verified, Scrive etc) och du vill ha alla dina e-signerade handlingar arkiverade på samma ställe',
            className: 'automation-signmethod-external',
          },
        ]}
        onChange={(ev) => {
          API.saveAnnualReportValue(annualReport.reportId, 'signMethod', ev.target.value, abortSignal, onChange, {
            immediate: true,
          })
        }}
      />

      {shouldIncludeArsredovisningDatum(annualReport) && annualReport.signMethod !== 'bankid' && (
        <React.Fragment>
          <Box mt={3} />
          <ArsredovisningDatumEditor
            annualReport={annualReport}
            onChange={onChange}
            disabled={isApproved || annualReport.locked}
          />
        </React.Fragment>
      )}
      <Box mt={3} />
      <SignatureDatesEditor
        annualReport={annualReport}
        onChange={async () => {
          await onChange()
          setCoaSignerEmailReactKey(coaSignerEmailReactKey + 1)
        }}
        disabled={isApproved || annualReport.locked}
        onSignLinkSent={async () => {
          await onChange()
          setCoaSignerEmailReactKey(coaSignerEmailReactKey + 1)
        }}
      />

      {annualReport.revisionsberattelseKrav && annualReport.signMethod !== 'bankid' && (
        <React.Fragment>
          <Box mt={2} />
          <AuditDateEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
        </React.Fragment>
      )}

      {annualReport.revisionsberattelseKrav && revberModifiedEnabled && (
        <React.Fragment>
          <Box mt={3} />
          <ModifieradRevisionsberattelseEditor
            annualReport={annualReport}
            onChange={onChange}
            disabled={isApproved || annualReport.locked}
          />
        </React.Fragment>
      )}

      <Box mt={3} />
      {annualReport.signMethod === 'bankid' && (
        <React.Fragment>
          {!annualReport.hasOriginal && (
            <React.Fragment>
              {!isPreparingSigning && (
                <RmTooltip
                  visible={signingPrepareIsBlocked}
                  title="För att ladda upp årsredovisningen till Kivra för signering så måste flik 7: Noter vara godkänd och giltigt personnummer + e-mail krävs för samtliga företrädare."
                >
                  <Button
                    variant={'contained'}
                    endIcon={<SendIcon />}
                    onClick={async () => {
                      setIsPreparingSigning(true)
                      await API.signingPrepare(annualReport.reportId, abortSignal)
                      setIsPreparingSigning(false)
                      onChange()
                    }}
                    disabled={signingPrepareIsBlocked}
                    className="automation-create-original"
                  >
                    Ladda upp årsredovisning (original) till Kivra för signering med BankID
                  </Button>
                </RmTooltip>
              )}
              {isPreparingSigning && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <div>
                    <CircularProgress size={20} />
                  </div>
                  <div>Laddar upp årsredovisning (original) till Kivra för signering ...</div>
                </div>
              )}
            </React.Fragment>
          )}
          {annualReport.hasOriginal && !annualReport.hasOriginalSigned && (
            <React.Fragment>
              {annualReport.signatures.length === 1 && (
                <React.Fragment>
                  <Button
                    variant="contained"
                    endIcon={<OpenInNewIcon />}
                    onClick={() => {
                      window.open(signingUrl, 'kivra')
                      API.saveTAction(annualReport.reportId, 'single-signer-sign', abortSignal)
                    }}
                    className="automation-single-signer-sign"
                  >
                    Signera årsredovisning (original) med Kivra
                  </Button>
                  <Box mt={3} />
                </React.Fragment>
              )}
              {annualReport.signatures.length > 1 && (
                <div style={{ fontSize: '0.95em' }}>
                  Samtliga företrädare behöver nu öppna{' '}
                  <a href={signingUrl} target="_blank" rel="noopener noreferrer">
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <div>{signingUrl}</div>
                      <div>
                        <OpenInNewIcon fontSize="small" style={{ marginTop: '6px' }} />
                      </div>
                    </div>
                  </a>{' '}
                  och signera årsredovisningen med BankID.
                  <Box mt={3} />
                </div>
              )}
              {(annualReport.sentSignLinks.length > 0 || annualReport.signatures.length > 1) && (
                <div>
                  <h4 style={{ marginTop: 0, marginBottom: '5px' }}>Skickade signeringslänkar</h4>
                  {annualReport.sentSignLinks.map((ssLink, ssIdx) => (
                    <ul key={ssIdx} style={{ margin: 0 }}>
                      <li>
                        {ssLink.timestamp}&nbsp;&nbsp;&nbsp;Signeringslänk skickades till {ssLink.name} ({ssLink.sentTo}
                        )
                      </li>
                    </ul>
                  ))}
                  {annualReport.sentSignLinks.length === 0 && (
                    <ul style={{ margin: 0 }}>
                      <li>
                        <i>
                          Inga signeringslänkar har skickats, använd Hantera-menyn till höger om respektive företrädare
                          för att skicka en signeringslänk.
                        </i>
                      </li>
                    </ul>
                  )}
                  <Box mt={3} />
                </div>
              )}
              <Box mt={2} />
            </React.Fragment>
          )}
          {annualReport.hasOriginalSigned && (
            <div>
              Samtliga företrädare har nu signerat{' '}
              <a
                href={`/api/up/download-signed-pdf?reportId=${annualReport.reportId}`}
                className="automation-download-signed-pdf"
              >
                årsredovisningen
              </a>{' '}
              och efter årsstämman görs inlämning till Bolagsverket från nästa flik.
            </div>
          )}
        </React.Fragment>
      )}

      <Box mt={5} />
      <h3>Fastställelseintyg</h3>
      <ArsstammaDatumEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
      <Box mt={2} />
      <CoaSignerEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
      <Box mt={2} />
      {arsstammaJusteradDispositionEnabled && (
        <React.Fragment>
          <RmCheckbox
            checked={annualReport.arsstammaJusteradDisposition}
            onChange={(checked) => {
              API.saveAnnualReportValue(
                annualReport.reportId,
                'arsstammaJusteradDisposition',
                checked,
                abortSignal,
                onChange,
                { immediate: true }
              )
            }}
            disabled={isApproved}
            label={'Årsstämman godkände ej föreslagen utdelning'}
          />
          {annualReport.arsstammaJusteradDisposition && (
            <table>
              <tbody>
                <tr>
                  <td style={{ width: FIRST_CELL_WIDTH }}>På årsstämman beslutad aktieutdelning</td>
                  <td>
                    <EditableXbrlValue
                      xbrlName="ArsstammaResultatDispositionAktieutdelning"
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={isApproved}
                      allowNegative={false}
                    />
                  </td>
                </tr>

                {renderEditableReadonlyRow(
                  annualReport.financialYears[0].xbrlValues,
                  'ArsstammaResultatDispositionBalanserasINyRakning',
                  'På årsstämman beslutad balansering i ny räkning',
                  'kr'
                )}
              </tbody>
            </table>
          )}
        </React.Fragment>
      )}
      {coaSigner && (
        <React.Fragment>
          {annualReport.signMethod !== 'bankid' && (
            <React.Fragment>
              <Box mt={2} />
              <div>Personnummer för undertecknare av fastställelseintyg</div>
              <Box mt={1} />
              <EditablePersonnummer
                className={`automation-coa-personnr`}
                value={coaSigner.personNr}
                onChange={(ev) => {
                  API.saveForetradareValue(coaSigner.foretradareId, 'personNr', ev.target.value, abortSignal, onChange)
                }}
                disabled={isApproved}
              />
            </React.Fragment>
          )}
          <React.Fragment>
            <Box mt={2} />
            <div>E-post för undertecknare av fastställelseintyg</div>
            <Box mt={1} />
            <RmTextField
              key={coaSignerEmailReactKey}
              className={`automation-coa-email`}
              defaultValue={coaSigner.email}
              onChange={(ev) => {
                API.saveForetradareValue(coaSigner.foretradareId, 'email', ev.target.value, abortSignal, onChange)
              }}
              disabled={isApproved}
              autoComplete="email"
              style={{ width: '400px' }}
            />
          </React.Fragment>
        </React.Fragment>
      )}
      <br />
      <br />
    </React.Fragment>
  )
}

FardigstallEditor.propTypes = {
  userConfig: PropTypes.object.isRequired,
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

export default withUserConfig(FardigstallEditor)
