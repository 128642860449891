import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio/index.js'
import RadioGroup from '@mui/material/RadioGroup/index.js'
import FormControlLabel from '@mui/material/FormControlLabel/index.js'

import { useAbortSignal } from '../../AppFetch.js'
import { renderEditableReadonlyRow } from './EditableTableUtils.jsx'
import { makeFinancialYearTitleHtml } from '../../../shared-universal/AnnualReportRender.js'
import { DATA_FIELD_RIGHT_MARGIN } from './EditableTableUtils.jsx'
import EditableAmount from './EditableAmount.jsx'
import EditableXbrlValue from './EditableXbrlValue.jsx'
import { hasNonZeroRawAmount, taxLanFranAktieagareVisible } from '../../../shared-universal/AnnualReportUtils.js'
import { periodiseringsfondYears } from '../../../shared-universal/RemakerUtils.js'
import EditableXbrlSlider from './EditableXbrlSlider.jsx'
import { formatCell } from '../../../shared-universal/AnnualReportUtils.js'
import { getUrlParam } from '../../UrlUtils.js'
import RmClickToSelect from './RmClickToSelect.jsx'
import API from '../../API.js'
import RmSwitch from './RmSwitch.jsx'
import RmCard from './RmCard.jsx'
import RmEditorHeading3 from './RmEditorHeading3.jsx'

const EditableTaxDetails = ({ annualReport, onChange, isApproved }) => {
  const abortSignal = useAbortSignal()
  const [advancedIsVisible, setAdvancedIsVisible] = useState(false)
  const firstCellStyle = {
    width: '100%',
  }
  const intentedFirstCellStyle = {
    ...firstCellStyle,
    paddingLeft: '50px',
  }
  const underskottSectionVisible =
    annualReport.financialYears.length > 1 ||
    annualReport.financialYears[0].xbrlOverrides['rmSkattOutnyttjatUnderskottFranForegaendeAr'] ||
    annualReport.financialYears[0].xbrlOverrides[
      'rmSkattReduktionAvOutnuttjatUnderskottMedHansynTillBeloppssparrAckordKonkurs'
    ] ||
    annualReport.financialYears[0].xbrlOverrides[
      'rmSkattReduktionAvOutnuttjatUnderskottMedHansynTillKoncernbidragssparrFusionssparr'
    ]
  const rmUrsprungligtBokfortResultat =
    annualReport.financialYears[0].xbrlValues['rmUrsprungligtBokfortResultat']?.value ?? 0
  const rmBeraknatAretsResultat = annualReport.financialYears[0].xbrlValues['rmBeraknatAretsResultat']?.value ?? 0
  const hasUrsprungligtBokfortResultat = rmUrsprungligtBokfortResultat != 0

  let lastYearIdxWithVisiblePFondBalance = 5
  periodiseringsfondYears(annualReport.financialYears[0].lastDay).forEach((year, yearIdx) => {
    if (annualReport.financialYears[0].xbrlValues[`rmPFondAlder${yearIdx}Ingaende`]?.rawAmount) {
      lastYearIdxWithVisiblePFondBalance = Math.max(lastYearIdxWithVisiblePFondBalance, yearIdx)
    }
  })

  return (
    <React.Fragment>
      <h3>Skatteberäkning</h3>
      <RmSwitch label="Visa alla fält" value={advancedIsVisible} onChange={setAdvancedIsVisible} />
      <table>
        <thead>
          <tr>
            <th style={firstCellStyle}></th>
            <td
              key={annualReport.financialYears[0].financialYearId}
              dangerouslySetInnerHTML={{
                __html: makeFinancialYearTitleHtml(annualReport.financialYears[0], 'duration'),
              }}
              style={{
                textAlign: 'right',
                paddingRight: DATA_FIELD_RIGHT_MARGIN,
                fontWeight: 'bold',
                verticalAlign: 'top',
              }}
            ></td>
          </tr>
        </thead>
        <tbody>
          {renderEditableReadonlyRow(
            annualReport.financialYears[0].xbrlValues,
            'AretsResultat',
            annualReport.financialYears[0].xbrlValues['AretsResultat']?.value < 0
              ? '4.2 Årets resultat, förlust'
              : '4.1 Årets resultat, vinst'
          )}
          <tr>
            <td style={firstCellStyle}>
              <h4>4.3 Bokförda kostnader som inte skall dras av</h4>
            </td>
          </tr>
          <tr>
            <td style={firstCellStyle}>A) Skatt på årets resultat</td>
            <td>
              <EditableAmount
                className={`automation-SkattPeriodensResultatInteSkattemassigtAvdragsgill`}
                value={
                  annualReport.financialYears[0].xbrlValues['SkattPeriodensResultatInteSkattemassigtAvdragsgill']?.value
                }
                unit="kr"
                disabled={true}
                forceSign={'+'}
              />
            </td>
          </tr>

          {(advancedIsVisible ||
            annualReport.financialYears[0].xbrlValues[
              'rmSkattNedskrivningFinansiellaTillgangarInteSkattemassigtAvdragsgilla'
            ]?.value !== undefined) && (
            <tr>
              <td style={firstCellStyle}>B) Nedskrivning av finansiella tillgångar</td>
              <td>
                <EditableXbrlValue
                  xbrlName="rmSkattNedskrivningFinansiellaTillgangarInteSkattemassigtAvdragsgilla"
                  financialYear={annualReport.financialYears[0]}
                  abortSignal={abortSignal}
                  onSaved={onChange}
                  disabled={isApproved}
                  forceSign={'+'}
                />
              </td>
            </tr>
          )}
          <tr>
            <td style={firstCellStyle}>C) Andra bokförda kostnader</td>
          </tr>

          <tr>
            <td style={intentedFirstCellStyle}>Representation, ej avdragsgill</td>
            <td>
              <EditableXbrlValue
                xbrlName="rmSkattIckeAvdragsgillRepresentation"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={isApproved}
                forceSign={'+'}
              />
            </td>
          </tr>
          <tr>
            <td style={intentedFirstCellStyle}>Övriga externa kostnader, ej avdragsgilla</td>
            <td>
              <EditableXbrlValue
                xbrlName="rmSkattIckeAvdragsgillOvrigaExternaKostnader"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={isApproved}
                forceSign={'+'}
              />
            </td>
          </tr>
          <tr>
            <td style={intentedFirstCellStyle}>Personalrepresentation, ej avdragsgill</td>
            <td>
              <EditableXbrlValue
                xbrlName="rmSkattIckeAvdragsgillPersonalrepresentation"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={isApproved}
                forceSign={'+'}
              />
            </td>
          </tr>
          <tr>
            <td style={intentedFirstCellStyle}>Räntekostnader för skatter och avgifter</td>
            <td>
              <EditableXbrlValue
                xbrlName="rmSkattIckeAvdragsgillRantekostnaderForSkatterOchAvgifter"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={isApproved}
                forceSign={'+'}
              />
            </td>
          </tr>
          <tr>
            <td style={firstCellStyle}>
              <h4>4.5 Bokförda intäkter som inte ska tas upp</h4>
            </td>
          </tr>
          <tr>
            <td style={firstCellStyle}>B) Utdelning (icke-skattepliktig)</td>
            <td>
              <EditableXbrlValue
                xbrlName="rmSkattUtdelningInteSkattepliktig"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={isApproved}
                forceSign={'-'}
              />
            </td>
          </tr>
          <tr>
            <td style={firstCellStyle}>C) Andra bokförda intäkter: Skattefria ränteintäkter</td>
            <td>
              <EditableXbrlValue
                xbrlName="rmSkattSkattefriaRanteintakter"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={isApproved}
                forceSign={'-'}
              />
            </td>
          </tr>
          {annualReport.financialYears.length > 1 && (
            <>
              <tr>
                <td style={firstCellStyle}>
                  <h4>4.6 Intäkter som ska tas upp men som inte ingår i det redovisade resultatet</h4>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <small>
                    Periodiseringfonder vid beskattningsårets ingång (
                    {formatCell(
                      annualReport.financialYears.length > 1
                        ? annualReport.financialYears[1].xbrlValues['Periodiseringsfonder']
                        : { rawAmount: 0, value: 0 },
                      {
                        decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                      }
                    )}{' '}
                    kr) multiplicerat med schablonintäktsräntan (
                    {
                      annualReport.financialYears[0].xbrlValues['SCHABLONINTAKTSPROCENT_FOR_PERIODISERINGSFONDER']
                        ?.value
                    }
                    %) ger beräknad schablonintäkt på{' '}
                    {
                      annualReport.financialYears[0].xbrlValues['rmSkattBeraknadSchablonintaktPaPeriodiseringsfonder']
                        ?.value
                    }{' '}
                    kr.
                  </small>
                </td>
              </tr>
              <tr>
                <td style={firstCellStyle}>
                  A) Beräknad schablonintäkt på kvarvarande periodiseringsfonder vid beskattningsårets ingång
                </td>
                <td>
                  <EditableAmount
                    className={`automation-rmSkattBeraknadSchablonintaktPaPeriodiseringsfonder`}
                    defaultValue={
                      annualReport.financialYears[0].xbrlValues['rmSkattBeraknadSchablonintaktPaPeriodiseringsfonder']
                        ?.value
                    }
                    unit="kr"
                    disabled={true}
                    forceSign={'+'}
                  />
                </td>
              </tr>
              <tr>
                <td style={firstCellStyle}>B) Beräknad schablonintäkt på fondandelar ägda vid kalenderårets ingång</td>
                <td>
                  <EditableXbrlValue
                    xbrlName="rmSkattBeraknadSchablonintaktPaFondandelar"
                    financialYear={annualReport.financialYears[0]}
                    abortSignal={abortSignal}
                    onSaved={onChange}
                    disabled={isApproved}
                    forceSign={'+'}
                  />
                </td>
              </tr>
              <tr>
                <td style={firstCellStyle}>D) Uppräknat belopp vid återföring av periodiseringsfonder</td>
                <td>
                  <EditableAmount
                    className={`automation-rmPFondUppraknatBeloppVidAterforingAvPeriodiseringsfond`}
                    defaultValue={
                      annualReport.financialYears[0].xbrlValues[
                        'rmPFondUppraknatBeloppVidAterforingAvPeriodiseringsfond'
                      ]?.value
                    }
                    unit="kr"
                    disabled={true}
                    forceSign={'+'}
                  />
                </td>
              </tr>
            </>
          )}
          {underskottSectionVisible && (
            <React.Fragment>
              <tr>
                <td>
                  <h4>4.14 Underskott</h4>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <small>
                    Fältet &quot;Outnyttjat underskott från föregående år&quot; skall innehålla det underskott som
                    rapporterades i fält 1.2 i förra årets deklaration.
                  </small>
                </td>
              </tr>
              <tr>
                <td style={firstCellStyle}>A) Outnyttjat underskott från föregående år</td>
                <td>
                  <EditableXbrlValue
                    xbrlName="rmSkattOutnyttjatUnderskottFranForegaendeAr"
                    financialYear={annualReport.financialYears[0]}
                    abortSignal={abortSignal}
                    onSaved={onChange}
                    disabled={isApproved}
                    forceSign={'-'}
                  />
                </td>
              </tr>
              {(advancedIsVisible ||
                annualReport.financialYears[0].xbrlValues[
                  'rmSkattReduktionAvOutnuttjatUnderskottMedHansynTillBeloppssparrAckordKonkurs'
                ]?.value !== undefined) && (
                <tr>
                  <td style={firstCellStyle}>
                    B) Reduktion av outnyttjat underskott med hänsyn till beloppsspärr, ackord eller konkurs
                  </td>
                  <td>
                    <EditableXbrlValue
                      xbrlName="rmSkattReduktionAvOutnuttjatUnderskottMedHansynTillBeloppssparrAckordKonkurs"
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={isApproved}
                      forceSign={'+'}
                    />
                  </td>
                </tr>
              )}
              {(advancedIsVisible ||
                annualReport.financialYears[0].xbrlValues[
                  'rmSkattReduktionAvOutnuttjatUnderskottMedHansynTillKoncernbidragssparrFusionssparr'
                ]?.value !== undefined) && (
                <tr>
                  <td style={firstCellStyle}>
                    C) Reduktion av outnyttjat underskott med hänsyn till koncernbidragsspärr, fusionsspärr m.m.
                  </td>
                  <td>
                    <EditableXbrlValue
                      xbrlName="rmSkattReduktionAvOutnuttjatUnderskottMedHansynTillKoncernbidragssparrFusionssparr"
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={isApproved}
                      forceSign={'+'}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td style={{ height: '1.3em' }}></td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
      {(annualReport.financialYears.length > 1 ||
        annualReport.financialYears[0].xbrlValues['rmPFondAvsattningMax']?.value > 0) && (
        <RmCard>
          <RmEditorHeading3>Periodiseringsfonder</RmEditorHeading3>
          <table>
            <tbody>
              {annualReport.financialYears.length > 1 && (
                <React.Fragment>
                  {periodiseringsfondYears(annualReport.financialYears[0].lastDay)
                    .reverse()
                    .map((year, reverseArrayIdx, allPFondYears) => {
                      const yearIdx = allPFondYears.length - 1 - reverseArrayIdx
                      const xbrlName = `rmPFondAlder${yearIdx}Ingaende`
                      if (yearIdx == 0 || yearIdx > lastYearIdxWithVisiblePFondBalance) {
                        return null
                      }

                      return (
                        <tr key={`pfond-${year}`}>
                          <td style={firstCellStyle}>Periodiseringsfond {year}</td>
                          <td>
                            <EditableXbrlValue
                              xbrlName={xbrlName}
                              financialYear={annualReport.financialYears[0]}
                              abortSignal={abortSignal}
                              onSaved={onChange}
                              disabled={isApproved}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  {renderEditableReadonlyRow(
                    annualReport.financialYears[0].xbrlValues,
                    'rmPFondAterforingMax',
                    'Summa periodiseringsfonder',
                    'kr'
                  )}
                  {renderEditableReadonlyRow(
                    annualReport.financialYears[1].xbrlValues,
                    'Periodiseringsfonder',
                    'Periodiseringsfonder (jämförelseår; totalt ingående saldo)',
                    'kr'
                  )}
                  {renderEditableReadonlyRow(
                    annualReport.financialYears[0].xbrlValues,
                    'rmPFondBalansVsIngaendeDifferens',
                    'Differens mellan ovan totalt ingående saldo och periodiseringsfonder (jämförelseår)',
                    'kr'
                  )}
                  <EditableXbrlSlider
                    title="Återföring från periodiseringfonder"
                    xbrlName="rmPFondAterforing"
                    allowNegative={false}
                    financialYear={annualReport.financialYears[0]}
                    min={annualReport.financialYears[0].xbrlValues['rmPFondAterforingMin']?.value}
                    max={annualReport.financialYears[0].xbrlValues['rmPFondAterforingMax']?.value}
                    abortSignal={abortSignal}
                    onChange={onChange}
                    disabled={isApproved}
                  />
                </React.Fragment>
              )}
            </tbody>
          </table>
          {annualReport.financialYears.length > 1 && (
            <table>
              <tbody>
                {periodiseringsfondYears(annualReport.financialYears[0].lastDay)
                  .reverse()
                  .map((year, reverseArrayIdx, allPFondYears) => {
                    const yearIdx = allPFondYears.length - 1 - reverseArrayIdx
                    if (yearIdx == 0 || yearIdx > lastYearIdxWithVisiblePFondBalance) {
                      return null
                    }
                    const aterforingThisYear =
                      annualReport.financialYears[0].xbrlValues[`rmPFondAlder${yearIdx}Aterforing`]
                    if (!hasNonZeroRawAmount(aterforingThisYear)) {
                      return null
                    }

                    return (
                      <tr key={`pfond-upprakning-${year}`}>
                        <td style={firstCellStyle}>Periodiseringsfond {year}</td>
                        <td
                          style={{
                            textAlign: 'right',
                            paddingRight: DATA_FIELD_RIGHT_MARGIN,
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <RmClickToSelect
                            text={formatCell(aterforingThisYear, {
                              decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                            })}
                          />{' '}
                          kr
                        </td>
                        <td
                          style={{
                            textAlign: 'right',
                            paddingRight: DATA_FIELD_RIGHT_MARGIN,
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {formatCell(
                            annualReport.financialYears[0].xbrlValues[
                              `rmPFondAlder${yearIdx}AterforingUpprakningsprocent`
                            ],
                            {
                              decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                            }
                          )}
                          %
                        </td>
                        <td
                          style={{
                            textAlign: 'right',
                            paddingRight: DATA_FIELD_RIGHT_MARGIN,
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <RmClickToSelect
                            text={formatCell(
                              annualReport.financialYears[0].xbrlValues[`rmPFondAlder${yearIdx}AterforingUpprakning`],
                              {
                                decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                              }
                            )}
                          />{' '}
                          kr
                        </td>
                      </tr>
                    )
                  })}

                {renderEditableReadonlyRow(
                  annualReport.financialYears[0].xbrlValues,
                  'rmPFondUppraknatBeloppVidAterforingAvPeriodiseringsfond',
                  'Uppräknat belopp vid återföring av periodiseringsfonder (infogas som fält 4.6 D)',
                  'kr',
                  { colSpan: 3 }
                )}
              </tbody>
            </table>
          )}
          <table>
            <tbody>
              {annualReport.financialYears[0].xbrlValues['rmPFondAvsattningMax']?.value > 0 && (
                <React.Fragment>
                  {renderEditableReadonlyRow(
                    annualReport.financialYears[0].xbrlValues,
                    'rmSkattSkattemassigtResultatInnanAvsattningTillPFond',
                    'Skattemässigt resultat innan avsättning till periodiseringsfond',
                    'kr',
                    {}
                  )}
                  <EditableXbrlSlider
                    title={`Avsättning till periodiseringsfond ${annualReport.financialYears[0].lastDay.slice(0, 4)}`}
                    xbrlName="rmPFondAvsattning"
                    financialYear={annualReport.financialYears[0]}
                    allowNegative={false}
                    min={annualReport.financialYears[0].xbrlValues['rmPFondAvsattningMin']?.value}
                    max={annualReport.financialYears[0].xbrlValues['rmPFondAvsattningMax']?.value}
                    abortSignal={abortSignal}
                    onChange={onChange}
                    disabled={isApproved}
                  />
                </React.Fragment>
              )}
            </tbody>
          </table>
        </RmCard>
      )}
      <RmEditorHeading3 style={{ marginTop: '24px' }}>Skatt</RmEditorHeading3>
      {hasUrsprungligtBokfortResultat && rmUrsprungligtBokfortResultat !== rmBeraknatAretsResultat && (
        <React.Fragment>
          <RadioGroup
            style={{ marginLeft: '20px' }}
            value={annualReport.anvandBokfortResultat}
            onChange={async (ev) => {
              API.saveAnnualReportValue(
                annualReport.reportId,
                'anvandBokfortResultat',
                ev.target.value === 'true',
                abortSignal,
                onChange,
                { immediate: true }
              )
            }}
          >
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="Använd beräknat resultat/skatt"
              labelPlacement="end"
              disabled={isApproved}
            />
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="Använd bokfört resultat/skatt"
              labelPlacement="end"
              disabled={isApproved}
            />
          </RadioGroup>
          <table>
            <tbody>
              {renderEditableReadonlyRow(
                annualReport.financialYears[0].xbrlValues,
                'rmSkattSkattemassigtResultat',
                'Skattemässigt resultat',
                'kr'
              )}
              {renderEditableReadonlyRow(
                annualReport.financialYears[0].xbrlValues,
                'rmSkattBeskattningsbarInkomst',
                'Beskattningsbar inkomst',
                'kr'
              )}
              {renderEditableReadonlyRow(
                annualReport.financialYears[0].xbrlValues,
                'TAX_PERCENTAGE',
                'Årets bolagsskatt',
                '%'
              )}
              <React.Fragment>
                {renderEditableReadonlyRow(
                  annualReport.financialYears[0].xbrlValues,
                  'rmUrsprungligBokfordSkatt',
                  'Årets skatt (bokförd)',
                  'kr'
                )}
              </React.Fragment>
              <React.Fragment>
                {renderEditableReadonlyRow(
                  annualReport.financialYears[0].xbrlValues,
                  'rmUrsprungligtBokfortResultat',
                  'Årets resultat (bokfört)',
                  'kr'
                )}
              </React.Fragment>
            </tbody>
          </table>
        </React.Fragment>
      )}
      <br />
      <br />
      <br />
      <table>
        <tbody>
          {renderEditableReadonlyRow(
            annualReport.financialYears[0].xbrlValues,
            'rmSkattSkattemassigtResultat',
            'Skattemässigt resultat',
            'kr'
          )}
          {renderEditableReadonlyRow(
            annualReport.financialYears[0].xbrlValues,
            'rmSkattBeskattningsbarInkomst',
            'Beskattningsbar inkomst',
            'kr'
          )}
          {renderEditableReadonlyRow(
            annualReport.financialYears[0].xbrlValues,
            'TAX_PERCENTAGE',
            'Årets bolagsskatt',
            '%'
          )}
          {(advancedIsVisible ||
            annualReport.financialYears[0].xbrlValues['rmSkattejustering']?.value !== undefined) && (
            <React.Fragment>
              {renderEditableReadonlyRow(
                annualReport.financialYears[0].xbrlValues,
                'rmBeraknadSkattAretsResultatInnanJusteringForAndradBeskattning',
                'Årets skatt innan skattejustering pga ändrad beskattning / restituerad skatt',
                'kr'
              )}
              <tr>
                <td style={firstCellStyle}>Skattejustering (pga ändrad beskattning, restituerad skatt osv)</td>
                <td>
                  <EditableXbrlValue
                    xbrlName="rmSkattejustering"
                    financialYear={annualReport.financialYears[0]}
                    abortSignal={abortSignal}
                    onSaved={onChange}
                    disabled={isApproved}
                    forceSign={'-'}
                    forceAllowNegative={true}
                  />
                </td>
              </tr>
            </React.Fragment>
          )}
          {renderEditableReadonlyRow(
            annualReport.financialYears[0].xbrlValues,
            'rmBeraknadSkattAretsResultat',
            'Årets skatt (beräknad)',
            'kr'
          )}
          {renderEditableReadonlyRow(
            annualReport.financialYears[0].xbrlValues,
            'rmBeraknatAretsResultat',
            'Årets resultat efter skatt (beräknat)',
            'kr'
          )}
        </tbody>
      </table>
      {taxLanFranAktieagareVisible(annualReport.financialYears) && (
        <React.Fragment>
          <hr />
          <h4>Övriga uppgifter till företagets deklaration</h4>
          <table>
            <tbody>
              {(advancedIsVisible ||
                annualReport.financialYears[0].xbrlValues['PositivtUnderlagForSarskildLoneskattPaPensionskostnader']
                  ?.value !== undefined) && (
                <tr>
                  <td style={firstCellStyle}>1.4 Underlag för särskild löneskatt på pensionskostnader</td>
                  <td>
                    <EditableXbrlValue
                      xbrlName="PositivtUnderlagForSarskildLoneskattPaPensionskostnader"
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={isApproved}
                      allowNegative={false}
                      className="automation-PositivtUnderlagForSarskildLoneskattPaPensionskostnader-0"
                    />
                  </td>
                </tr>
              )}
              {(advancedIsVisible ||
                annualReport.financialYears[0].xbrlValues['NegativtUnderlagForSarskildLoneskattPaPensionskostnader']
                  ?.value !== undefined) && (
                <tr>
                  <td style={firstCellStyle}>1.5 Negativt underlag för särskild löneskatt på pensionskostnader</td>
                  <td>
                    <EditableXbrlValue
                      xbrlName="NegativtUnderlagForSarskildLoneskattPaPensionskostnader"
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={isApproved}
                      allowNegative={false}
                      className="automation-NegativtUnderlagForSarskildLoneskattPaPensionskostnader-0"
                    />
                  </td>
                </tr>
              )}
              {(advancedIsVisible ||
                annualReport.financialYears[0].xbrlValues[
                  'UnderlagForAvkastningsskatt15ProcentForsakringsforetagMFlSamtAvsattTillPensioner'
                ]?.value !== undefined) && (
                <tr>
                  <td style={firstCellStyle}>1.6 A) Försäkringsföretag m.fl. samt avsatt till pensioner 15 %</td>
                  <td>
                    <EditableXbrlValue
                      xbrlName="UnderlagForAvkastningsskatt15ProcentForsakringsforetagMFlSamtAvsattTillPensioner"
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={isApproved}
                      allowNegative={false}
                      className="automation-UnderlagForAvkastningsskatt15ProcentForsakringsforetagMFlSamtAvsattTillPensioner-0"
                    />
                  </td>
                </tr>
              )}
              {(advancedIsVisible ||
                annualReport.financialYears[0].xbrlValues[
                  'UnderlagForAvkastningsskatt15ProcentUtlandskaPensionsforsakringar'
                ]?.value !== undefined) && (
                <tr>
                  <td style={firstCellStyle}>1.6 B) Utländska pensionsförsäkringar 15 %</td>
                  <td>
                    <EditableXbrlValue
                      xbrlName="UnderlagForAvkastningsskatt15ProcentUtlandskaPensionsforsakringar"
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={isApproved}
                      allowNegative={false}
                      className="automation-UnderlagForAvkastningsskatt15ProcentUtlandskaPensionsforsakringar-0"
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td style={firstCellStyle}>4.20 Lån från aktieägare (fysisk person) vid beskattningsårets utgång</td>
                <td>
                  <EditableXbrlValue
                    xbrlName="rmSkattLanFranAktieagareVidBeskattningsaretsUtgang"
                    financialYear={annualReport.financialYears[0]}
                    abortSignal={abortSignal}
                    onSaved={onChange}
                    disabled={isApproved}
                    allowNegative={false}
                    className="automation-rmSkattLanFranAktieagareVidBeskattningsaretsUtgang-0"
                  />
                </td>
              </tr>
              {(advancedIsVisible ||
                annualReport.financialYears[0].xbrlValues['Pensionskostnader']?.value !== undefined) && (
                <tr>
                  <td style={firstCellStyle}>4.21 Pensionskostnader</td>
                  <td>
                    <EditableXbrlValue
                      xbrlName="Pensionskostnader"
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={isApproved}
                      allowNegative={false}
                      className="automation-Pensionskostnader-0"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

EditableTaxDetails.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

export default EditableTaxDetails
